import logo from './logo.svg';
import './App.css';
import Home from './Home';
import bgimg from './images/bgimg.jpeg'

function App() {
  return (
    <div className="App" style={{backgroundImage:bgimg}}>
     
    <Home/>
    </div>
  );
}

export default App;
